import Api from "./api/Api";

export default {
  /**
   * Sends a contact request
   *
   * @param {Object} params
   *
   * @property {String} params.name
   * @property {String} params.email
   * @property {String} params.subject
   * @property {String} params.message
   *
   * @returns {ApiPromise<Object>}
   */
  sendContactRequest(params) {
    return Api
      .post(Api.route(`/contact`), params)
      .then(response => response);
  },
};
