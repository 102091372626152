export const DELIMITER = "!!";
export const TITLE_SUFFIX = "NDBS";

export const prependToTitle = (title = null) => title
  ? `${title} - ${TITLE_SUFFIX}`
  : TITLE_SUFFIX;

export default {
  /**
   * Initializes this plugin
   *
   * @param {Object} Vue a Vue instance
   */
  install(Vue) {
    /**
     * Sets default title for route
     * 
     * @param {Object} route
     */
    Vue.prototype.$setTitle = route => {
      if (!route || !route.meta || !route.meta.title) {
        window.document.title = prependToTitle();
      }

      const title = route.meta.title
        .split(DELIMITER)
        .join("");

      window.document.title = prependToTitle(title);
    };

    /**
     * Updates title for a route
     *
     * @param {Object} route a this.$route in vue instance
     * @param {string} title a new title for page
     */
    Vue.prototype.$updateTitle = (route, title= TITLE_SUFFIX) => {
      if (!route || !route.meta || !route.meta.title) {
        return;
      }

      const search = `${DELIMITER}(.*)${DELIMITER}`;
      const regex = new RegExp(search, "g");

      const newTitle = route.meta.title
        .replace(regex, title);

      window.document.title = prependToTitle(newTitle);
    };
  },
};
