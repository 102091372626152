<template>
  <button class="simple-btn focused-no-outline" :class="classes" @click="onClick">
    Contact
  </button>
</template>

<script>

  /**
   * All available events
   */
  export const EVENTS = Object.freeze({
    CLICK: "click",
  });

  /**
   * ContactButton
   */
  export default {
    name: "ContactButton",
    props: {
      classes: {
        type: String,
        default: "",
      },
    },
    methods: {
      /**
       * Dispatches a click event
       */
      onClick() {
        this.$emit(EVENTS.CLICK);
      },
    },
  };
</script>
