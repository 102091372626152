<template>
  <div class="dialog-container">
    <div class="dialog-content">
      <h1 class="contact-us-title">Contact us</h1>
      <validation-observer ref="form">
        <form class="contact-us-form">
        <div class="dialog-content-item dialog-name">
          <validation-provider name="name" :rules="nameRules" v-slot="{ errors }">
            <i class="dialog-content-item-icon lni lni-user" />
            <input
              id="name"
              class="dialog-content-input-field focused-no-outline"
              type="text"
              name="name"
              placeholder="e"
              v-model="name"
              :class="errors.length !== 0 ? 'border-danger' : ''" />
            <label class="dialog-content-item-label" for="name">Name</label>

            <span v-if="errors.length !== 0" class="dialog-content-item-error text-danger mt-3">
              {{ nameErrorMessage }}
            </span>
          </validation-provider>
        </div>

        <div class="dialog-content-item dialog-email">
          <validation-provider name="email" :rules="emailRules" v-slot="{ errors }">
            <i class="dialog-content-item-icon lni lni-envelope" />
            <input
              id="email"
              class="dialog-content-input-field focused-no-outline"
              type="email"
              name="email"
              placeholder="e"
              v-model="email"
              :class="errors.length !== 0 ? 'border-danger' : ''" />
            <label class="dialog-content-item-label" for="email">Email</label>

            <span v-if="errors.length !== 0" class="dialog-content-item-error text-danger mt-3">
              {{ emailErrorMessage }}
            </span>
          </validation-provider>
        </div>

        <div class="dialog-content-item dialog-subject">
          <validation-provider name="subject" :rules="subjectRules" v-slot="{ errors }">
            <i class="dialog-content-item-icon lni lni-clipboard" />
            <input
              id="subject"
              class="dialog-content-input-field focused-no-outline"
              type="text"
              name="subject"
              placeholder="e"
              v-model="subject"
              :class="errors.length !== 0 ? 'border-danger' : ''" />
            <label class="dialog-content-item-label" for="subject">Subject</label>

            <span v-if="errors.length !== 0" class="dialog-content-item-error text-danger mt-3">
              {{ subjectErrorMessage }}
            </span>
          </validation-provider>
        </div>

        <div class="dialog-content-item dialog-message">
          <validation-provider name="message" :rules="messageRules" v-slot="{ errors }">
            <textarea
              id="message"
              class="dialog-content-input-field no-resize focused-no-outline"
              name="message"
              placeholder="e"
              v-model="message"
              :class="errors.length !== 0 ? 'border-danger' : ''" />
            <label class="dialog-content-item-label" for="message">Message</label>

            <span v-if="errors.length !== 0" class="dialog-content-item-error text-danger mt-3">
              {{ messageErrorMessage }}
            </span>
          </validation-provider>
        </div>

        <div class="dialog-content-error-message">
          <p class="text-danger" v-if="hasError">{{ errorMessage() }}</p>
          <p class="text-success" v-if="null !== response">{{ response }}</p>
        </div>

        <div class="dialog-content-action-items">
          <button
            class="simple-no-outline focused-no-outline"
            type="button"
            @click="onClose">
            Cancel
          </button>

          <button
            class="simple-btn dialog-send-btn focused-no-outline"
            type="submit"
            @click.prevent="sendContactRequest"
            :class="loading ? 'disabled' : ''"
            :disabled="loading">
            Send
          </button>
        </div>
      </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import ContactApi from "@/modules/common/ContactApi";

  /**
   * All available events
   */
  export const EVENTS = Object.freeze({
    CLICK: "click",
    CLOSE: "close",
  });

  /**
   * ContactDialog
   */
  export default {
    name: "ContactDialog",
    comments: {
      ValidationProvider,
      ValidationObserver,
    },
    computed: {
      /**
       * Checks if a form has an error
       *
       * @returns {Boolean}
       */
      hasError() {
        return null !== this.error;
      },
    },
    data() {
      return {
        loading: false,

        error: null,
        response: null,

        name: "",
        nameRules: "required|min:2|max:64",
        nameErrorMessage: "Name is required and should be from 2 to 64 characters long",

        email: "",
        emailRules: "required|email",
        emailErrorMessage: "A real email is required",

        subject: "",
        subjectRules: "required|min:2|max:64",
        subjectErrorMessage: "Subject is required and should be from 2 to 64 characters long",

        message: "",
        messageRules: "required|min:2|max:2048",
        messageErrorMessage: "Message is required and should be from 2 to 2048 characters long",
      };
    },
    methods: {
      /**
       * Dispatches a click event
       */
      onClick() {
        this.$emit(EVENTS.CLICK);
      },

      /**
       * Dispatches a close event
       */
      onClose() {
        this.$emit(EVENTS.CLOSE);
      },

      /**
       * Gets an error message
       *
       * @returns {String}
       */
      errorMessage() {
        const unknownErrorMessage = "An error has occurred, try again later";
        if (undefined === this.error.errors || "undefined" === this.error.errors) {
          return unknownErrorMessage;
        }

        return this.error.errors.error[0];
      },

      /**
       * Sends a contact request
       */
      async sendContactRequest() {
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }

        this.loading = true;
        this.error = null;

        const params = {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message,
        };

        this.error = null;

        ContactApi
          .sendContactRequest(params)
            .then(response => {
              this.response = response.message;
            })
            .catch(err => {
              this.error = err;
            })
            .finally(() => this.loading = false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dialog-container {
    position: fixed;
    display: flex;
    background: #0000007a;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .dialog-content {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
      align-self: center;
      border-radius: 3px;

      .contact-us-title {
        font-weight: 600;
        margin: 25px 0 0 35px;
      }

      .contact-us-form {
        display: grid;
        grid-gap: 25px;
        align-items: center;
        color: #8b8b8b;
        padding: 35px;
        border-radius: 5px;

        .dialog-content-item {
          position: relative;
          height: 50px;
          border: none;
          border-radius: 3px;

          .dialog-content-item-error {
            font-size: 12px;
          }

          .dialog-content-item-label {
            position: absolute;
            top: 17px;
            left: 35px;
            font-size: 14px;
            transition: all 0.2s ease;
          }

          .dialog-content-input-field {
            width: 100%;
            box-sizing: border-box;
            background-color: #FBFCFE;
            background-position: 10px 10px;
            background-repeat: no-repeat;
            border: none;
            padding: 14px 10px 10px 35px;
            color: #484848;

            &::placeholder {
              opacity: 0;
            }

            &:focus ~ label {
              top: 3px;
              left: 35px;
              font-size: 10px;
            }

            &:not(:placeholder-shown) ~ label {
              top: 3px;
              left: 35px;
              font-size: 10px;
            }
          }

          .dialog-content-item-icon {
            position: absolute;
            margin-top: 17px;
            margin-left: 10px;
          }
        }

        .dialog-name {
          grid-row: 1;
          grid-column: 1/3;
        }

        .dialog-email {
          grid-row: 2;
          grid-column: 1/3;
        }

        .dialog-subject {
          grid-row: 3;
          grid-column: 1/3;
        }

        .dialog-message {
          padding: 0;
          grid-row: 4;
          grid-column: 1/3;
          height: 100px;
          width: 100%;
          background-color: #FBFCFE;
          background-position: 10px 10px;
          background-repeat: no-repeat;
          border: none;

          .dialog-content-input-field {
            padding: 14px 10px 35px 35px;
          }
        }

        .dialog-content-error-message {
          display: flex;
          grid-row: 5;
          grid-column: 1/3;
          height: 30px;
          margin-top: 10px;
          justify-content: center;
        }

        .dialog-content-action-items {
          display: flex;
          grid-row: 6;
          grid-column: 2;
          grid-gap: 10px;
          justify-content: end;

          .dialog-send-btn {
            grid-row: 4;
            grid-column: 1/3;
            background: #4D8BF9;
            color: #FFFFFF;
          }
        }
      }
    }
  }
</style>
