/**
 * ValidationError class
 */
export class ValidationError extends Error {
  /**
   * ValidationError constructor
   *
   * @param {String} message
   * @param {Object} data
   */
  constructor(message, data = {}) {
    super(message);

    this.name = "ValidationError";
    this.message = message;
    this.code = data.hasOwnProperty("code") ? data.code : null;
    this.errors = data.hasOwnProperty("errors") ? data.errors : null;
  }

  /**
   * @returns {String}
   */
  toString() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      errors: this.errors,
      stacktrace: this.stack,
    };
  }
}
