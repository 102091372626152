/**
 * ApiPromise class
 */
export default class ApiPromise {
  /**
   * ApiPromise constructor
   *
   * @param {Promise}  request
   * @param {Canceler} cancelInvoker
   */
  constructor(request, cancelInvoker) {
    this.request = request;
    this.cancelInvoker = cancelInvoker;
  }

  /**
   * Appends fulfillment and rejection handlers to the promise
   *
   * @param {...Function} args - onFulfilled and onRejected
   * @returns {ApiPromise}
   */
  then(...args) {
    this.request = this.request.then(...args);
    return this;
  }

  /**
   * Appends a rejection handler callback to the promise
   *
   * @param {Function} onRejected
   * @returns {ApiPromise}
   */
  catch(onRejected) {
    this.request = this.request.catch(onRejected);
    return this;
  }

  /**
   * Appends a handler to the promise
   * The handler is called when the promise is settled, whether fulfilled or rejected.
   *
   * @param {Function} onFinally
   * @returns {ApiPromise}
   */
  finally(onFinally) {
    this.request = this.request.finally(onFinally);
    return this;
  }

  /**
   * Cancels current request
   *
   * @param {String} message
   * @returns {void}
   */
  cancel(message = "") {
    this.cancelInvoker && this.cancelInvoker(message);
  }
}
