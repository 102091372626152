import Axios from "axios";
import Qs from "qs";
import ApiPromise from "./ApiPromise/ApiPromise";

import {ValidationError} from "./error/ValidationError";
import {ConflictError} from "./error/ConflictError";
import {NotFoundError} from "./error/NotFoundError";

Axios.interceptors.request.use(config => {
  config.paramsSerializer = parameters => Qs.stringify(parameters, {
    arrayFormat: "brackets",
    encode: false,
    skipNulls: true,
  });

  return config;
});

Axios.interceptors.response.use(undefined, error => {
  if (!error.response) {
    console.error(error);
    throw error;
  }

  Promise.resolve()
    .then()
    .catch(startPromiseError => {
      if ("NavigationDuplicated" !== startPromiseError?.name) {
        throw startPromiseError;
      }
    });

  throw error;
});

/**
 * Handles an HTTP error
 *
 * @param {Object} error
 */
function handleError(error) {
  if (error && 400 === error.code) {
    throw new ValidationError("ValidationError: ", error);
  }

  if (error && 409 === error.code) {
    throw new ConflictError("ConflictError: ", error);
  }

  if (error && 404 === error.code) {
    throw new NotFoundError("NotFoundError: ", error);
  }

  throw error;
}

/**
 * Preconfigured HTTP API methods
 */
export default {
  /**
   * Creates full path with common prefix
   * including default current API version.
   *
   * If `path` parameter starts with '/'
   * then path
   *
   * @param {String} path
   * @returns {String} a full API path
   */
  route(path) {
    path = path.startsWith("/") ? path.substring(1) : path;
    return `/api/v1.0/${path}`;
  },

  /**
   * Does a GET request
   *
   * @param {String} url
   * @param {Object} params
   * @param {Object} requestConfig
   *
   * @returns {ApiPromise}
   */
  get(url, params = {}, requestConfig = {}) {
    const source = Axios.CancelToken.source();
    const config = {
      params: params,
      cancelToken: source.token,
      ...requestConfig,
    };

    const request = Axios
      .get(url, config)
      .then(response => response.data);

    return new ApiPromise(request, source.cancel);
  },

  /**
   * Does a POST request
   *
   * @param {String} url
   * @param {Object} params
   * @param {Object} config
   *
   * @returns {ApiPromise}
   */
  post(url, params = {}, config = {}) {
    const source = Axios.CancelToken.source();
    Object.assign(config, {cancelToken: source.token});

    const request = Axios
      .post(url, params, config)
      .then(response => response.data)
      .catch(err => handleError(err.response?.data));

    return new ApiPromise(request, source.cancel);
  },

  /**
   * Does a PUT request
   *
   * @param {String} url
   * @param {Object} params
   * @param {Object} config
   *
   * @returns {ApiPromise}
   */
  put(url, params = {}, config = {}) {
    const source = Axios.CancelToken.source();
    Object.assign(config, {cancelToken: source.token});

    const request = Axios
      .put(url, params, config)
      .then(response => response.data)
      .catch(err => handleError(err.response.data));

    return new ApiPromise(request, source.cancel);
  },

  /**
   * Does a DELETE request
   *
   * @param {String} url
   * @param {Object} params
   *
   * @returns {ApiPromise}
   */
  delete(url, params = {}) {
    const source = Axios.CancelToken.source();
    Object.assign(params, {cancelToken: source.token});

    const request = Axios
      .delete(url, params)
      .then(response => response.data)
      .catch(err => handleError(err.response.data));

    return new ApiPromise(request, source.cancel);
  },
};
