import Vue from "vue";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";

import App from "./App.vue";

import _ from "lodash";
import "./libs/CommonCss";

import DynamicTitle from "./plugins/DynamicTitle";

window._ = _;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(DynamicTitle);

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);

const vue = new Vue({
  render: h => h(App),
  components: {
    App,
  },
});

vue.$mount("#app");
