<template>
  <div class="body-container" @keydown.esc="closeContactDialog">
    <div class="header-container">
      <a class="company-name" href="#home">
        <img class="logo" src="/static/favicon/logo-1024x1024.svg">
        <p class="text">einlan</p>
      </a>

      <div class="menu-block">
        <ul class="menu-list">
          <li class="menu-item" v-for="menuItem in menu">
            <a class="menu-item-link" :href="menuItem.link">{{ menuItem.title }}</a>
          </li>

          <contact-button classes="ml-20" @click="toggleContactDialogOpened" />
        </ul>
      </div>
    </div>

    <div id="home" class="home-container">
      <div class="home-block">
        <div class="brand-image-block">
          <img src="/static/images/home_preview-612x612.jpg">
        </div>

        <div class="header-block">
          <h1 class="header-text">Software development &nbsp; and maintenance</h1>
        </div>

        <div class="description-block">
          <p class="description-text">
            Comprehensive web development: backend, frontend, cloud integrations. <br>
            Social Media Bots creation. AI integration
          </p>
        </div>
      </div>
    </div>

    <div id="services" class="services-container">
      <div class="services-block">
        <div class="service-instances">
          <div class="info-item shadow-sm rounded-4 mt-4 pt-2" v-for="service in services">
            <div class="p-4">
              <div class="float-start py-2 px-3 rounded-3 text-center" :class="service.iconBgClass">
                <i :class="service.iconClass" />
              </div>

              <div class="pt-5">
                <h6 class="mt-3">{{ service.title }}</h6>
                <p class="text-muted fs-15">{{ service.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="information">
          <h2 class="title">Affordable Services</h2>
          <p class="text">
            We specialize in comprehensive web development, offering backend and frontend solutions,
            seamless cloud integrations, and cutting-edge AI implementations
            <br><br>
            Elevate your online presence with our Social Media Bots,
            designed to boost engagement and streamline communication across various platforms
          </p>
        </div>
      </div>
    </div>

    <div id="portfolio" class="portfolio-container">
      <div class="portfolio-block">
        <div class="header-block">
          <h1 class="header-text">Portfolio</h1>
        </div>

        <div class="content-block">
          <a
            class="content-item"
            target=”_blank”
            v-for="portfolioProject in portfolioProjects"
            :href="portfolioProject.href">
            <span class="content-item-title">{{ portfolioProject.title }}</span>
            <span class="content-item-description">{{ portfolioProject.description }}</span>
            <img
              class="content-item-bg"
              alt="telegram bot"
              v-if="undefined !== portfolioProject.imagePath"
              :src="portfolioProject.imagePath"
              :class="portfolioProject.classes">
          </a>
        </div>
      </div>
    </div>

    <div id="contact" class="footer-container">
      <div class="footer-block">
        <h3 class="footer-text">Call or write to us</h3>

        <div class="contact-details-block">
          <div class="contact-details-item__email">
            <p class="title">Email</p>
            <a class="text" href="mailto:info@einlan.com" style="color: #FFFFFF">info@einlan.com</a>
          </div>

          <div class="contact-details-item__phone">
            <p class="title">Phone</p>
            <p class="text">+48 794 468 076</p>
          </div>
        </div>

        <hr>
      </div>

      <div class="copyright-details">
        Copyright © 2024 Einlan. All Rights Reserved.
      </div>
    </div>

    <contact-dialog v-if="true === contactDialogOpened" @close="closeContactDialog" />
  </div>
</template>

<script>
  import ContactButton from "@/modules/common/ContactButton.vue";
  import ContactDialog from "@/modules/common/ContactDialog.vue";

  export default {
    components: {
      ContactButton,
      ContactDialog,
    },
    data() {
      return {
        menu: [
          {
            title: "Home",
            link: "#home",
          },
          {
            title: "About",
            link: "#home",
          },
          {
            title: "Services",
            link: "#services",
          },
          {
            title: "Portfolio",
            link: "#portfolio",
          },
        ],
        services: [
          {
            iconBgClass: "bg-blue",
            iconClass: "lni lni-react",
            title: "Artificial Intelligence",
            description: "Integration with AI, enhancing applications with advanced artificial intelligence\n" +
                "                  capabilities to revolutionize user experiences and drive innovation",
          },
          {
            iconBgClass: "bg-blue-pink-dark",
            iconClass: "lni lni-code",
            title: "Web Development",
            description: "Comprehensive web solutions at enterprise and e-commerce levels,\n" +
                "                    leveraging cutting-edge technologies to empower and enhance online experiences",
          },
          {
            iconBgClass: "bg-blue-pink-light",
            iconClass: "lni lni-cloud",
            title: "Cloud",
            description: "Seamless and efficient integration solutions with AWS,\n" +
                "          empowering to optimize operations and harness the full potential of cloud technology",
          },
          {
            iconBgClass: "bg-pink",
            iconClass: "lni lni-reload",
            title: "Full Cycle",
            description: "End-to-end services encompassing research, design, development, implementation\n" +
                "                    and ongoing maintenance",
          },
        ],
        portfolioProjects: [
          {
            title: "Langless",
            description: "A groundbreaking Telegram bot fueled by AI, enabling users to write messages once and effortlessly translate them into multiple languages, offering unparalleled convenience and innovation",
            imagePath: "/static/images/langless_iphone-640x1280.png",
            href: "https://t.me/langless_bot",
          },
          {},
        ],
        contactDialogOpened: false,
      };
    },
    methods: {
      /**
       * Toggles a contact dialog
       */
      toggleContactDialogOpened() {
        this.contactDialogOpened = !this.contactDialogOpened;
      },

      /**
       * Closes a contact dialog
       */
      closeContactDialog() {
        this.contactDialogOpened = false;
      },
    },
  };
</script>
